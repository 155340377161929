// Dashboard Styles
.profile-page {
	.page-header {
	    background-image: url("../../../img/bg-profile.png");
		height: 320px;

	    @include media-breakpoint-down(xs) {
	        height: 240px;
	    }
	}

	.fileinput {
		.thumbnail {
			&.img-circle {
			    border-radius: 50%;
				width: 10rem;
				height: 10rem;
			}

			> img {
				height: auto;
				width: 100%;
				max-height: inherit;
			}
		}
	}

	.description {
		margin-top: .45rem;
    }

    .main-raised {
	    @include media-breakpoint-down(xs) {
            margin-left: 0;
            margin-right: 0;
            border-radius: 0;
	    }
	}
}
