body {
    background-color: $body-bg;
}

.title,
.card-title,
.info-title,
.footer-brand,
.footer-big h5,
.footer-big h4,
.media .media-heading {
    font-weight: 400;
    // font-family: Roboto; //$bootstrap-font-family-sans-serif;
}

.logo-image {
    // width: 46px;
    height: 46px;
    // border-radius: 50%;
    overflow: hidden;
    margin-top: -6px;

    img {
        height: 36px;
    }
}

// .form-control {
//     height: auto;
// }

.btn-wechat,
.btn-weixinweb,
.btn-weixin {
    @include soical-btn($color-wechat);
}

.btn-alipay {
    @include soical-btn($color-alipay);
}

.btn-qq {
    @include soical-btn($color-qq);
}

.btn-weibo {
    @include soical-btn($color-weibo)
}

.logo-transparent {
    display: none;
}

.navbar {
    +.alert {
        position: fixed;
        top: 70px;
        right: 0;
        left: 0;
        z-index: 1030;
    }
}
//首页
.d-car-slide {
    margin-top: -20px;
}
.navbar-transparent {
    .logo-transparent {
        display: block;
    }

    .logo-scroll {
        display: none;
    }

    +.alert {
        top: 85px;
    }
}

.snackbar-error {
    .snackbar-content {
        background-color: $red;
    }
}

.bmd-form-group {
    .bmd-label-static {
        font-size: 0.6875rem;
        top: 1rem;

        ~.form-check {
            margin-top: 0.6rem;
        }
    }
}

.back-background,
.card-background,
.front-background {
    background-position: 50%;
    background-size: cover;
    text-align: center;

    .card-body {
        position: relative;
        z-index: 2;
        min-height: 280px;
        padding-top: 40px;
        padding-bottom: 40px;
        max-width: 440px;
        margin: 0 auto;
    }

    .card-category,
    .card-description,
    small {
        color: hsla(0, 0%, 100%, .7) !important;
    }

    .card-title {
        color: #fff;
        margin-top: 10px;
    }

    &:not(.card-pricing) {
        .btn {
            margin-bottom: 0;
        }
    }

    &:after {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: block;
        left: 0;
        top: 0;
        content: "";
        background-color: rgba(0, 0, 0, .56);
        border-radius: 6px;
    }
}

.card-pricing {
    text-align: center;

    h1 {
        small {
            font-size: 18px;
            display: inline-flex;
            height: 0;
        }
    }

    .icon {
        padding: 10px 0 0;

        i {
            font-size: 55px;
            border: 1px solid #e5e5e5;
            border-radius: 50%;
            width: 130px;
            line-height: 130px;
            height: 130px;
        }
    }
}

.card-profile,
.card-testimonial {
    margin-top: 30px;
    text-align: center;

    .card-avatar {
        margin: -50px auto 0;
        border-radius: 50%;
        overflow: hidden;
        padding: 0;
        @include box-shadow($bmd-shadow-2dp);
        // box-shadow: 0 16px 38px -12px rgba(0, 0, 0, .56), 0 4px 25px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, .2)

        img {
            width: 100%;
            height: auto;
        }

        +.card-body {
            margin-top: 15px;
        }
    }

    .card-body {
        +.card-footer {
            margin-top: -15px;
        }
    }
}

.card-profile {
    margin-top: 15px;
    .card-avatar {
        width: 130px;
        max-width: 130px;
        max-height: 130px;
    }
}

.media {
    .avatar {
        margin: 0 auto;
        width: 64px;
        height: 64px;
        overflow: hidden;
        border-radius: 50%;
        margin-right: 15px;
        box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12), 0 3px 5px -1px rgba(0, 0, 0, .2);

        img {
            width: 100%;
        }
    }
}

.card-media {
    .media {
        .media-left {
            width: 35%;
        }

        .media-body {
            min-width: 50%;
        }
    }
}

.bg-mckoy {
    background-image: url("../../img/bg-night-star.jpg");
}

.error-page {
    >.container {
        position: absolute !important;
        top: 50%;
        left: 50%;
        z-index: 2;
        transform: translate(-50%, -50%);
        text-align: center;
        color: #fff;
        padding: 0 15px;
        width: 100%;
        max-width: 880px;
    }

    .title {
        font-size: 12em;
        color: #fff;
        letter-spacing: 14px;
        font-weight: 700;
    }
}

.card-scale {
    transition: all .2s cubic-bezier(.4, 0, .2, 1);

    &:hover {
        transform: scale(1.02);
    }
}

.overlay-hover-then-show {
    overflow: hidden;

    .card-img-overlay {
        opacity: 0;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-transition: all .4s ease-in-out;
        transition: all .4s ease-in-out
    }

    img {
        -webkit-transition: all .4s linear;
        transition: all .4s linear;
    }

    &:hover {
        img {
            -ms-transform: scale(1.2);
            -webkit-transform: scale(1.2);
            transform: scale(1.2);
        }

        .card-img-overlay {
            opacity: 1;
            filter: alpha(opacity=100);
            color: #fff;
        }
    }
}

.card-less-padding {
    .card-body,
    .card-footer {
        padding: .3rem .8rem;
    }
}

.btn {
    &.btn-fab,
    &.btn-just-icon {
        .btn-group-sm &,
        &.btn-sm,
        &.btn-fab-mini{
            .fab,
            .fas,
            .far {
                font-size: $mdb-btn-icon-size-mini;
                line-height: $mdb-btn-fab-size-mini;
            }
        }

        .btn-group-lg &,
        &.btn-lg {
            .fab,
            .fas,
            .far {
                font-size: $mdb-btn-icon-size;
                line-height: $mdb-btn-fab-size-lg;
            }
        }

        .fab,
        .fas,
        .far {
            margin-top: 0;
            position: absolute;
            width: 100%;
            transform: none;
            left: 0;
            top: 0;
            height: 100%;

            line-height: $mdb-btn-fab-size;
            font-size: $mdb-btn-just-icon-font-size;
        }
    }
}
