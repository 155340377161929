.bg-login {
    @include bg-image('../../img/bg-login2.jpg');
}

.bg-register {
    @include bg-image('../../img/bg-register.jpg');
}

.gd-bg {
    @include bg-image('../../img/bj_02.jpg');
    &::before {
        background: rgba(0, 0, 0, 0);
      }
}

.jx-bg {

    @include bg-image('../../img/jx/jx-bg.jpg');
    &::before {
        background: rgba(0, 0, 0, 0);
      }
}

.jx-exam-01-bg {
    @include bg-image('../../img/jx/jx_pc_11.png');
}

.jx-exam-02-bg {
    @include bg-image('../../img/jx/jx_pc_12.png');
}

.jx-exam-03-bg {
    @include bg-image('../../img/jx/jx_pc_13.png');
}

.gd-exam-01-bg {
    @include bg-image('../../img/gd/03_11.png');
}

.gd-exam-time-bg {
    @include bg-image('../../img/gd/03_15_1.png');
}

.gd-exam-02-bg {
    @include bg-image('../../img/gd/03_19.png');
}

.gd-exam-03-bg {
    @include bg-image('../../img/gd/03_45.png');
}

.bg-login2 {
    @include bg-image('../../img/bg-login.jpg');
}

.card-login {
    .form {
        min-height: auto;
    }

    .bmd-form-group {
        padding-top: 18px;
    }

    .input-group {
        padding-bottom: 0;
        margin-top: 0;
    }

    .form-check {
        padding-top: 0;
        padding-left: 3px;

        label {
            padding-left: 33px;
        }
    }
}

.login-page {
    .footer {
        z-index: 999;
        bottom: 25px;

        a:hover {
            color: inherit;
            text-decoration: underline;
        }
    }
}

.pin-in-input {
    position: absolute;
    z-index: 10;
    right: 0;
    top: -.32rem;

    &.bmd-pin-input {
        top: 1.356rem;
    }
}

.pin-in-input + .form-control {
    padding-right: 5.1rem;
}

.signup-aside-left {
    width: 479px;
    height: 580px;
    background: url("../../../img/aside-register.png") no-repeat left top;
}

.social-sm {
    .btn-fab,
    .btn-just-icon {
        height: $mdb-btn-fab-size-mini + 1;
        min-width: $mdb-btn-fab-size-mini + 1;
        width: $mdb-btn-fab-size-mini + 1;

        .fa,
        .fab,
        .fas,
        .far {
            font-size: $mdb-btn-icon-size-mini;
            line-height: $mdb-btn-fab-size-mini;
        }
    }
}

.form-signup {
    margin-top: 50px !important;
}
.comc-footer-cp {
    color: #fff;
    font-size: 12px;
    height: 40px;
    line-height: 40px;
    white-space: nowrap;
    z-index: 2;
    position: fixed;
    bottom: 0px;
    width: 100%;
    .comc-footer-link-beian {
        font-size: 0.8em;
        margin: 0;
        li{
            list-style-type: none;
            display: inline-block;
            padding-right: 5px;
            color: #fff;
            a{
                color: #fff;
            }
        }
    }
}
