// full background image
@mixin bg-image($url) {
    background-image: url($url);
    background-size: cover;
    background-position: top center;
}

// social login button
@mixin soical-btn($color) {
    color: #fff !important;
    background-color: $color !important;
    border-color: $color !important;

    &:hover {
        color: #fff !important;
        background-color: darken($color, 10%) !important;
    }

    box-shadow: 0 2px 2px 0 rgba(85, 172, 238, .14),
                0 3px 1px -2px rgba(85, 172, 238, .2),
                0 1px 5px 0 rgba(85, 172, 238, .12) !important;
}

@mixin font-size($size: 0.875rem) {
    font-size: $size;
}

@mixin demension($width: 1.375rem, $height: 1.375rem) {
    //元素width、height，默认值为雪碧图部分图标的值
    width: $width;
    height: $height;
}
