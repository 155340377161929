.contact-page {
    .big-map {
        height: 55vh;
        width: 100%;
        display: block;
        position: relative;
    }

    .contact-content {
        padding-bottom: 40px;
        padding-top: 40px;
    }

    .container {
        max-width: 970px;
    }

    .info {
        padding-bottom: 10px;
        padding-top: 0;
    }
}

.main {
    background: #fff;
    position: relative;
    z-index: 3;


}

.main-raised {
    margin: -60px 30px 0;
    border-radius: 6px;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, .2);

    @include media-breakpoint-down(xs) {
        margin-left: 10px;
        margin-right: 10px;
    }
}
